import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import SearchArticle from "./components/SearchArticle";
import AppProvider from "./appContext";
import SearchProvider from "./searchContext";

const App = () => {
  return (
    <AppProvider>
      <SearchProvider>
        <SearchArticle />
      </SearchProvider>
    </AppProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
