import React, { useContext } from "react";
import Textfield from "./Textfield";
import searchArvix from "../functions/searchArvix";
import extractXMLResults from "../functions/extractXmlResults";
import { SearchContext } from "./../searchContext";

const Form = (props) => {
  const [searchState, setSearchState] = useContext(SearchContext);

  const handleSubmit = async (e) => {
    // prevents refresh of page
    // must be first to prevent page reloading and interfering with request
    e.preventDefault();
    console.log("You have submitted!");
    console.log(searchState);
    const xmlSearchResults = await searchArvix(searchState.searchValue);
    const jsonData = await extractXMLResults(xmlSearchResults.data);
    let entry = jsonData.feed.entry;

    if (entry === undefined) {
      entry = "none found";
    }

    setSearchState({
      ...searchState,
      searchResults: entry,
    });
  };

  const handleSearchBar = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setSearchState({ searchValue: "", searchResults: null });
    } else {
      setSearchState({ ...searchState, searchValue: value });
    }
  };
  return (
    <form className={props.className} onSubmit={handleSubmit}>
      <Textfield
        className="searchBar"
        focus="true"
        type="text:"
        label="🔎"
        value={searchState.searchValue}
        onChange={(e) => {
          handleSearchBar(e);
        }}
      />
    </form>
  );
};

export default Form;
