import React, { useContext, useEffect, useState } from "react";
import ShowResults from "./ShowResults";
import Form from "./Form";
import { SearchContext } from "./../searchContext";
import Button from "./button";
import { AppContext } from "./../appContext";
import { array } from "prop-types";

const SearchArticle = () => {
  const [searchState, setSearchState] = useContext(SearchContext);
  const [appState, setAppState] = useContext(AppContext);

  useEffect(() => {
    // console.log("search article: " + searchState.searchResults);
  }, [searchState]);

  useEffect(() => {
    console.log("appstate: " + appState.darkMode);
  }, [appState]);

  const toggleDarkMode = () => {
    console.log("toggling darkmode");
    console.log(appState.darkMode);
    return appState.darkMode === true ? false : true;
  };

  return (
    <section className={appState.darkMode ? "dark" : ""}>
      <Form
        className={
          //TODO
          searchState.searchResults &&
          searchState.searchResults !== "none found" &&
          searchState.searchValue.length > 0
            ? "hasResults"
            : ""
        }
      />
      <Button
        id="darkMode"
        name={appState.darkMode ? "🌖" : "🌒"}
        handleClick={() => {
          setAppState({
            ...appState,
            darkMode: toggleDarkMode(),
          });
        }}
      />
      <ShowResults searchState={{ searchState }} />
    </section>
  );
};

export default SearchArticle;
